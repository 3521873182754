<template>
  <tr>
    <td />
    <template v-if="Mode === 'khoi-tao'">
      <td
          v-for="item in HeaderKhoNH"
          :key="item.value"
          class="text-center red--text text-h6"
      >
        <template
          v-if="item.value === 'GiaNY'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(GiaNY)}}</span>
        </template>
        <template
            v-if="item.value === 'GiaNhap'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(GiaNhap)}}</span>
        </template>
      </td>
    </template>
    <template v-if="Mode === 'ton-kho'">
      <td
          v-for="item in HeaderKhoKH"
          :key="item.value"
          class="text-center red--text text-h6"
      >
        <template
            v-if="item.value === 'GiaNY'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(GiaNY)}}</span>
        </template>
        <template
            v-if="item.value === 'GiaNhap'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(GiaNhap)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanTT'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(LNTT)}}</span>
        </template>
        <template
            v-if="item.value === 'ChietKhauTT'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(CKTT)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanS1'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(LNS1)}}</span>
        </template>
        <template
            v-if="item.value === 'CPK'">
          <span class="font-weight-bold text-center align-center justify-center">{{DoiTien(CPK)}}</span>
        </template>
      </td>
    </template>
    <template v-if="Mode === 'don-hang'">
      <td
          v-for="item in HeaderKhoDH"
          :key="item.value"
          class="text-center red--text text-h6"
      >
        <template
            v-if="item.value === 'GiaNY'">
          <span class="font-weight-bold text-center">{{DoiTien(GiaNY)}}</span>
        </template>
        <template
            v-if="item.value === 'GiaNhap'">
          <span class="font-weight-bold text-center">{{DoiTien(GiaNhap)}}</span>
        </template>
        <template
            v-if="item.value === 'KDC'">
          <span class="font-weight-bold text-center">{{DoiTien(KDC)}}</span>
        </template>
        <template
            v-if="item.value === 'ChietKhauTT'">
          <span class="font-weight-bold text-center">{{DoiTien(CKTT)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanTT'">
          <span class="font-weight-bold text-center">{{DoiTien(LNTT)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanS1'">
          <span class="font-weight-bold text-center">{{DoiTien(LNS1)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanS2'">
          <span class="font-weight-bold text-center">{{DoiTien(LNS2)}}</span>
        </template>
        <template
            v-if="item.value === 'CPK'">
          <span class="font-weight-bold text-center">{{DoiTien(CPK)}}</span>
        </template>
        <template
            v-if="item.value === 'LoiNhuanGop'">
          <span class="font-weight-bold text-center">{{DoiTien(LNG)}}</span>
        </template>
        <template
            v-if="item.value === 'PhiShipNoiDia'">
          <span class="font-weight-bold text-center">{{DoiTien(Ship)}}</span>
        </template>
        <template
            v-if="item.value === 'TongLoiNhuan'">
          <span class="font-weight-bold text-center">{{DoiTien(TLN)}}</span>
        </template>
        <template
            v-if="item.value === 'LNR'">
          <span class="font-weight-bold text-center">{{DoiTien(LNR)}}</span>
        </template>
        <template
            v-if="item.value === 'DTK'">
          <span class="font-weight-bold text-center">{{DoiTien(DTK)}}</span>
        </template>
      </td>
    </template>

  </tr>
</template>

<script>

import {DoiTien} from "@/plugins/helper/string";
import HeaderKhoNH from '@/plugins/headers/kho-nganh-hang.json';
import HeaderKhoKH from '@/plugins/headers/kho-kho-hang.json';
import HeaderKhoKHAdmin from '@/plugins/headers/kho-kho-hang-admin.json';
import HeaderKhoDH from '@/plugins/headers/kho-don-hang.json';
import HeaderKhoDHAdmin from '@/plugins/headers/kho-don-hang-admin.json';
export default {
  name: "Kho-HeaderList-KhoHang",
  props:["Mode", "Manager", "GiaNhap", "GiaNY", "LNTT", "CKTT", "LNS1", "LNS2", "CPK", "KDC", "TLN", "LNG", "LNR", "Ship", "DTK"],
  data(){
    return {
      HeaderKhoNH,
      HeaderKhoKH: this.Manager ? HeaderKhoKHAdmin:HeaderKhoKH,
      HeaderKhoDH: this.Manager ? HeaderKhoDHAdmin:HeaderKhoDH,
    }
  },
  methods:{
    DoiTien,
  },
}
</script>

<style scoped>

</style>