<template>
  <div>
    <v-dialog
      v-model="box"
      persistent
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>Yêu cầu xuất kho</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="bill"
                label="Mã Bill"
                hint="Mã bill sẽ xuất tự động sau khi lệnh xuất thành công"
                persistent-placeholder
                persistent-hint
                readonly
                disabled
                large
                outlined
              />
            </v-col>
            <v-col
              cols="6"
            >
              <Loader :status="createState" />
            </v-col>
            <v-col
              cols="12"
            />
            <v-col
              cols="6"
            >
              <v-text-field
                  v-model="Request.TenNguoiMua"
                  label="Tên người mua"
                  persistent-placeholder
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-text-field
                  v-model="Request.TenNguoiNhan"
                  label="Tên người nhận"
                  persistent-placeholder
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-text-field
                  v-model="Request.SDTNguoiMua"
                  label="SĐT người mua"
                  persistent-placeholder
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-text-field
                  v-model="Request.SDTNguoiNhan"
                  label="SĐT người nhận"
                  persistent-placeholder
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-checkbox
                  v-model="Copy"
                  label="Trùng người nhận"
                  @change="doAutoCopy"
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-textarea
                  v-model="Request.DCNguoiNhan"
                  label="Địa chỉ người nhận"
                  persistent-placeholder
              />
            </v-col>
            <v-col
                cols="6"
            >
              <v-currency-field
                  :value="sumKDC"
                  label="Khoản điều chỉnh"
                  persistent-placeholder
                  readonly
              />
              <br />
              <v-btn
                  dark
                  color="blue"
                  @click="boxKHO = true"
              >
                Điều chỉnh
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
                cols="6"
            >
              <v-currency-field
                  :value="sumPT"
                  label="Phụ thu"
                  persistent-placeholder
                  readonly
              />
              <br />
              <v-btn
                dark
                color="blue"
                @click="boxPT = true"
              >
                Phụ thu
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
                cols="6"
            >
              <span class="text-h5 pb-2">
                Hình thức thanh toán
              </span>
              <br />
              <v-btn
                dark
                class="mx-2"
                color="green"
                @click="Request.ThanhToan = 'COD'"
              >
                <v-icon>
                  {{Request.ThanhToan === 'CK' ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked-outline'}}
                </v-icon>
                COD
              </v-btn>
              <v-btn
                  dark
                  class="mx-2"
                  color="orange"
                  @click="Request.ThanhToan = 'CK'"
              >
                <v-icon>
                  {{Request.ThanhToan === 'COD' ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked-outline'}}
                </v-icon>
                Chuyển Khoản
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :dark="!disabledCreate"
              :disabled="disabledCreate"
              :loading="createState"
              class="ma-2"
              @click="doCreate"
              large
              color="green"
          >
            Xin xuất
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="doClose"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="boxKHO"
        persistent
        max-width="900px"
    >
      <v-card flat>
        <v-card-title>Quản lý khoản điều chỉnh</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Mã Kho
                </th>
                <th class="text-left">
                  Hình ảnh
                </th>
                <th class="text-left">
                  Giá NY
                </th>
                <th class="text-left">
                  Chiết khấu
                </th>
                <th class="text-left">
                  Lợi nhuận
                </th>
                <th class="text-left">
                  Khoản điều chỉnh
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in KHO"
                  :key="index"
              >
                <td>{{ item.MaKho }}</td>
                <td>
                  <v-img
                      :src="item.Image"
                      :lazy-src="item.Image"
                      v-if="item.Image"
                  />
                  <span v-else>Không có hình</span>
                </td>
                <td>{{ DoiTien(item.GiaNY) }}</td>
                <td>{{ item.CK }}%</td>
                <td>{{ DoiTien(item.LoiNhuan) }}</td>
                <td>
                  <v-currency-field
                    v-model="item.KDC"
                    label="Khoản điều chỉnh"
                    :max="item.LoiNhuan"
                  />
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            @click="boxKHO = false"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="boxPT"
      persistent
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>Quản lý phụ thu</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="8"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left" style="width: 80%">
                      Phụ thu
                    </th>
                    <th class="text-left"  style="width: 20%">
                      Phí
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(pt,index) in Request.PhuThu"
                      :key="index"
                  >
                    <td>{{ pt.Muc }}</td>
                    <td>{{ DoiTien(pt.Phi) }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="PTData.Muc"
                label="Mục PT"
                class="mx-2 row"
              />
              <v-currency-field
                  v-model="PTData.Phi"
                  label="Phí phụ thu"
                  class="mx-2 row"
              />
              <br />
              <v-btn
                  dark
                  class="ma-2"
                  small
                  color="blue"
                  @click="doAddPT"
              >
                Thêm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              dark
              class="ma-2"
              @click="doClosePT"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Status} from "@/plugins/helper/dstatus";
import {DoiTien} from "@/plugins/helper/string";
import Query from '@/plugins/query/kho-hoa-don';
import Loader from '@/components/Loader.vue';
import {mapGetters} from "vuex";
export default {
  name: "XuatKho",
  props:["Notice","doDownload"],
  components:{
    Loader,
  },
  data(){
    return {
      createState: false,
      notice: new Status(this.$swal),
      KHO: [],
      boxKHO: false,
      boxPT: false,
      box: false,
      bill: null,
      Copy: false,
      PTData:{
        Muc: null,
        Phi: 0
      },
      Request:{
        TenNguoiMua: null,
        SDTNguoiMua: null,
        TenNguoiNhan: null,
        SDTNguoiNhan: null,
        DCNguoiNhan: null,
        GiaBanThucTe: 0,
        PhuThu: [],
        ThanhToan:"COD"
      },
    }
  },
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
    disabledCreate(){
      const {
        TenNguoiNhan,
        SDTNguoiNhan,
        DCNguoiNhan,
        TenNguoiMua,
        SDTNguoiMua,
      } = this.Request;
      if(!TenNguoiMua || !SDTNguoiNhan || !DCNguoiNhan || !TenNguoiNhan || !SDTNguoiMua) return true;
      if(this.bill) return true;
      if(this.createState) return true;
      return false;
    },
    sumPT(){
      const pt = [];
      this.Request.PhuThu.map(item=>pt.push(parseInt(item.Phi)));
      return pt.reduce((partialSum, a) => partialSum + a, 0);
    },
    sumKDC(){
      const kdc = [];
      this.KHO.map(item=>kdc.push(parseInt(item.KDC)));
      return kdc.reduce((partialSum, a) => partialSum + a, 0);
    }
  },
  watch:{
    Request: {
      handler () {
        const {Copy} = this;
        const {TenNguoiMua, SDTNguoiMua} = this.Request;
        if(Copy){
          this.Request.TenNguoiNhan = TenNguoiMua;
          this.Request.SDTNguoiNhan = SDTNguoiMua;
        }
      },
      deep: true,
    },
  },
  methods:{
    DoiTien,
    doCreate(){
      let Request = JSON.parse(JSON.stringify(this.Request));
      Request['Items'] = this.KHO;
      this.createState = true;
      return Query.qrCreate(Request).then(json=>{
        if(json.success){
          this.bill = json.success.MaHD ? json.success.MaHD:json.data?.MaHD;
          this.notice.success(`Đã xin xuất thành công - Mã hóa đơn của bạn: ${this.bill}`);
          this.doClose();
          this.$emit('doDownload');
        }
        if(json.error){
          this.notice.error(json.error);
        }
      }).catch(e=>this.notice.error(e.message)).finally(()=>{
        this.createState = false;
      });
    },
    doAutoCopy(){
      if(this.Copy){
        const {TenNguoiMua, SDTNguoiMua} = this.Request;
        this.Request.TenNguoiNhan = TenNguoiMua;
        this.Request.SDTNguoiNhan = SDTNguoiMua;
      }
    },
    doAddPT(){
      const {Muc, Phi} = this.PTData;
      if(!Muc || !Phi) return this.notice.error("Nhập dữ liệu !!!");
      if(isNaN(Phi) || Phi <= 0) return this.notice.error("Mức phí không hợp lệ !!!");
      this.Request.PhuThu.push(this.PTData);
      this.PTData = {
        Muc: null,
        Phi: 0
      };
    },
    doClosePT(){
      this.bill = null;
      this.boxPT = false;
      this.PTData = {
        Muc: null,
        Phi: 0
      };
    },
    doOpen(items = null){
      if(items){
        if(Array.isArray(items)){
          this.KHO = items.map(item=>{
            let CK = (item.CK === null || item.CK === undefined) ? 0:item.CK === 0 ? item.CKA:item.CKB;
            const {CKA, CKB} = this.loginInfo;
            const myCK = item.LoaiCK === 0 ? CKA:CKB;
            CK = (CK > myCK) ? myCK:CK;
            return {
              _id: item._id,
              MaKho: item.MaKho,
              Image: item.Images ? item.Images.length > 0 ? item.Images[0]:'':'',
              GiaNY: item.GiaNY,
              LoaiCK: item.CK,
              CK: CK,
              LoiNhuan: item.GiaNY*CK/100,
              KDC: 0,
            }
          });
        }
      }
      this.bill = null;
      this.box=true;
    },
    doClose(){
      this.box=false;
      this.bill=null;
      this.Request = {
        TenNguoiMua: null,
        SDTNguoiMua: null,
        TenNguoiNhan: null,
        SDTNguoiNhan: null,
        DCNguoiNhan: null,
        PhuThu: [],
        ThanhToan:"COD"
      };
    }
  }
}
</script>

<style scoped>

</style>