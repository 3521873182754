<template>
<div>
    <v-dialog
        v-model="box"
      persistent
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>
          NHẬP FILE EXCEL DỮ LIỆU KHO HÀNG
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="auto"
            >
              <v-file-input
                  v-model="file"
                  show-size
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  label="Chọn file Excel"
              />
            </v-col>
             <v-col cols="12" v-if="file">
               <xlsx-read :file="file">
                 <xlsx-json>
                   <template #default="{collection}">
                     <div>
                       Số lượng: {{ parseJson(collection) }}
                     </div>
                   </template>
                 </xlsx-json>
               </xlsx-read>
               <v-tabs
                   background-color="blue"
                   v-model="tab"
                   v-if="data.length > 0"
               >
                 <v-tab
                  color="green"
                 >
                   Sản phẩm hợp lệ
                 </v-tab>
                 <v-tab
                  color="red"
                 >
                   Sản phẩm không hợp lệ
                 </v-tab>
                 <v-tabs-items v-model="tab">
                   <v-tab-item>
                     <v-data-table
                         :headers="headers"
                         :items="validData"
                     >
                       <template v-slot:[`item.GiaNY`]="{item}">
                         {{DoiTien(item.GiaNY)}}
                       </template>
                       <template v-slot:[`item.GiaNhap`]="{item}">
                         {{DoiTien(item.GiaNhap)}}
                       </template>
                       <template v-slot:[`item.CPK`]="{item}">
                         {{DoiTien(item.CPK)}}
                       </template>
                     </v-data-table>
                   </v-tab-item>
                   <v-tab-item>
                      <p
                        v-for="(item,index) in data.filter(v=>v.error)"
                        :key="index"
                      >
                        {{item.error}}
                      </p>
                   </v-tab-item>
                 </v-tabs-items>
               </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <Loader :status="updateState" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            :dark="!updateState"
            :disabled="updateState || validData.length <= 0"
            :loading="updateState"
            color="green"
            @click="doImport"
          >
            Nhập dữ liệu
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="doClose"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { XlsxRead, XlsxJson } from "vue-xlsx"
import {mapGetters} from "vuex";
import Query from '@/plugins/query/kho-hang';
import {DoiTien} from "@/plugins/helper/string";
export default {
  name: "NhapKho",
  components:{
    XlsxRead,
    XlsxJson,
    Loader,
  },
  props:["doDownload","Notice"],
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
    headers(){
      let headers = [
      { text: 'Mã kho', value: 'MaKho', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Mã SP', value: 'MaSP', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Thương hiệu', value: 'ThuongHieu', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Thông số KT', value: 'ThongSo', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Tình trạng', value: 'TinhTrang', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Mô tả', value: 'MoTa', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Năm SX', value: 'NamSX', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'K.Lượng(kg)', value: 'KL', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Giá nhập', value: 'GiaNhap', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Giá NY', value: 'GiaNY', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
      { text: 'Chi phí khác', value: 'CPK', align: 'center',width: "50px",class:"grey lighten-2 black--text",sortable:false },
      ];
      if(this.loginInfo._perm.toString() !== 'admin'){
        return headers.slice(0,10);
      }
      return headers;
    },
    validData(){
      return this.data.filter(v=>!v.error);
    }
  },
  data(){
    return {
      updateState: false,
      box: false,
      file: null,
      data: [],
      tab:0,
    }
  },
  methods:{
    DoiTien,
    doImport(){
      this.updateState = true;
      return Query.qrExcelImport(this.validData).then(json=>{
        if(json.success){
          this.doClose();
          this.$emit('doDownload');
        }
        if(json.error){
          this.$emit('Notice', json.error);
        }
      }).catch(e=>this.$emit('Notice', e.message)).finally(()=>this.updateState=false);
    },
    doClose(){
      this.box = false;
      this.file = null;
      this.data = [];
    },
    doOpen(){
      this.data = [];
      this.file = null;
      this.box = true;
    },
    parseJson(data){
      if(data !== null){
        const tt = ["100","95","90","85","80"];
        this.data = data.map((item,index)=>{
          if(!item['__EMPTY'] || (item['__EMPTY'] && item['__EMPTY'].toString() === "STT")) return null;
          const new_data = {
            "MaKho": item['__EMPTY_1'],
            "MaSP": item['__EMPTY_2'],
            "ThuongHieu": item['__EMPTY_3'],
            "ThongSo": item['__EMPTY_4'],
            "TinhTrang": (item['__EMPTY_5']*100).toString(),
            "NamSX": item['__EMPTY_6'],
            "MoTa": item['__EMPTY_7'],
            "KL": item['__EMPTY_8'],
            "GiaNhap": item['__EMPTY_9'],
            "GiaNY": item['__EMPTY_10'],
            "CPK": item['__EMPTY_11'],
          };
          if(new_data['MaKho'] && new_data['MaSP'] && new_data['ThuongHieu'] && new_data['ThongSo'] && new_data['NamSX'] && new_data['TinhTrang'] && tt.includes(new_data['TinhTrang'].toString())){
            new_data['TinhTrang'] = new_data['TinhTrang']+'%';
            return new_data;
          }else{
            let e = '';
            if(!new_data['MaSP']) e = 'Thiếu mã sp';
            if(!new_data['ThuongHieu']) e = 'Thiếu thương hiệu';
            if(!new_data['ThongSo']) e = 'Thiếu thông số';
            if(!new_data['TinhTrang']) e = 'Thiếu tình trạng';
            if(!new_data['NamSX']) e = 'Thiếu năm sản xuất';
            if(!tt.includes(new_data['TinhTrang'].toString())) e = 'Tình trạng không hợp lệ - '+new_data['TinhTrang'];
            return {error: `Dòng: ${index+4} - Dữ liệu không hợp lệ - ${e}`};
          }
        }).filter(v=>v !== null);
        return this.data.length;
      }else{
        return 0;
      }
    }
  }
}
</script>

<style scoped>

</style>