<template>
  <div>
    <v-file-input
        ref="uploader"
        v-model="file"
        show-size
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        class="hide"
    />
    <xlsx-read :file="file">
      <xlsx-json>
        <template #default="{collection}">
          <div>
            {{ parseJson(collection) }}
          </div>
        </template>
      </xlsx-json>
    </xlsx-read>
    <v-dialog
      v-model="box"
      persistent
      max-width="900px"
    >
      <v-card flat>
        <v-card-title>Thông tin tracking</v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="data"
          >
            <template v-slot:[`item.Error`]="{item}">
              <span class="red--text" v-if="item.Error">{{item.Error}}</span>
              <span class="green--text" v-else>OK</span>
            </template>
            <template v-slot:[`item.PhiShipNoiDia`]="{item}">
              {{DoiTien(item.PhiShipNoiDia)}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :dark="!importState"
            color="green"
            :disabled="importState || dataValid <= 0"
            :loading="importState"
            @click="doImport()"
          >
            Nhập
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="doClose"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { XlsxRead, XlsxJson } from "vue-xlsx"
import Loader from "@/components/Loader";
import {DoiTien} from "@/plugins/helper/string";
export default {
  name: "Nhap-Tracking",
  components:{
    XlsxRead,
    XlsxJson,
    Loader,
  },
  props:["update"],
  data(){
    return {
      importState: false,
      box: false,
      file: null,
      data: [],
      headers:[
        { text: 'Mã kho', value: 'MaKho', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Phí ship nội địa', value: 'PhiShipNoiDia', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Tracking', value: 'Tracking', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Trạng thái', value: 'Error', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
      ],
    }
  },
  computed:{
    dataValid(){
      return this.data.filter(f=>!f.Error).length;
    }
  },
  methods:{
    DoiTien,
    doImport(){
      this.importState = true;
      return this.$emit("update", this.data.filter(f=>!f.Error));
    },
    doOpen(){
      this.file = null;
      this.data = [];
      this.box = true;
      this.importState = false;
      this.$refs.uploader.$refs.input.click();
    },
    doClose(){
      this.box = false;
    },
    parseJson(data) {
      if (data !== null) {
        this.data = data.map(item=>{
          if(!item['STT'] || (item['STT'] && item['STT'].toString() === "STT")) return null;
          if(item['MaKho'] && item['PhiShipNoiDia'] && item['Tracking']) {
            return {
              "MaKho": item['MaKho'],
              "PhiShipNoiDia": item['PhiShipNoiDia'],
              "Tracking": item['Tracking'],
            };
          }
          if(item['MaKho']){
            let e = '';
            if(!item['PhiShipNoiDia']) e = 'Chưa có phí ship nội địa';
            if(!item['Tracking']) e = 'Chưa có tracking';
            return {
              "MaKho": item['MaKho'],
              "Tracking": item['Tracking'],
              "PhiShipNoiDia": item['PhiShipNoiDia'],
              "Error": e,
            }
          }
          return null;
        }).filter(v=>v !== null);
      }
      return null;
    }
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>