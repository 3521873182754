<template>
  <v-dialog
    v-model="box"
    persistent
    max-width="900px"
    ransition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="doClose()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          THÔNG TIN TRACKING: {{trackingDetails.Tracking}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        Mã Kho:{{trackingDetails.MaKho}}
      </v-card-title>
      <v-card-text>
        <v-responsive
            :aspect-ratio="16/9"
            class="overflow-y-auto"
        >
          <v-timeline dense>
            <v-slide-x-reverse-transition
                group
                hide-on-leave
            >
              <v-timeline-item
                  v-for="(item,index) in trackingData.THONG_TIN"
                  :key="index"
                  :color="(item.TRANG_THAI === 'Phát thành công') ? 'green':'blue'"
                  :icon="(item.TRANG_THAI === 'Phát thành công') ? 'mdi-truck-check':'mdi-truck-delivery'"
                  small
                  fill-dot
              >
                <v-alert
                    :value="true"
                    :color="(item.TRANG_THAI === 'Phát thành công') ? 'green':'blue'"
                    :icon="(item.TRANG_THAI === 'Phát thành công') ? 'mdi-truck-check':'mdi-truck-delivery'"
                    class="white--text"
                >
                  <p>{{item.NGAY}} - {{item.GIO}} - {{item.TRANG_THAI}}</p>
                  <p>{{item.TRANG_THAI}} - {{item.VI_TRI}} - {{item.DIEN_THOAI}}</p>
                </v-alert>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
          </v-timeline>
        </v-responsive>
        <Loader :status="loading" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Query from '@/plugins/query/kho-hang';
import Loader from '@/components/Loader.vue';
import {Status} from "@/plugins/helper/dstatus";
export default {
  name: "Kho-Xem-Tracking",
  components:{
    Loader,
  },
  data(){
    return {
      notice: new Status(this.$swal),
      loading: false,
      box: false,
      trackingData: {
        THONG_TIN: [],
      },
      trackingDetails:{
        ID:null,
        MaKho:null,
        Tracking: null,
      }
    }
  },
  methods:{
    doTracking(){
      this.loading = true;
      return Query.qrTracking(this.trackingDetails.ID).then(json=>{
        const {data, error} = json;
        if(data) this.trackingData = data;
        if(error) this.notice.error(error);
      }).finally(()=>this.loading = false);
    },
    doOpen(item){
      this.trackingData = {
        THONG_TIN: [],
      };
      this.trackingDetails = {
        ID:item._id,
        MaKho: item.MaKho,
        Tracking: item.Tracking,
      };
      this.doTracking();
      this.box = true;
    },
    doClose(){
      this.box = false;
      this.trackingData = {
        THONG_TIN: [],
      };
      this.trackingDetails = {
        ID:null,
        MaKho:null,
        Tracking: null,
      };
    }
  },
}
</script>

<style scoped>

</style>