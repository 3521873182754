var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.box),callback:function ($$v) {_vm.box=$$v},expression:"box"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" NHẬP FILE EXCEL DỮ LIỆU KHO HÀNG ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-file-input',{attrs:{"show-size":"","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","label":"Chọn file Excel"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),(_vm.file)?_c('v-col',{attrs:{"cols":"12"}},[_c('xlsx-read',{attrs:{"file":_vm.file}},[_c('xlsx-json',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('div',[_vm._v(" Số lượng: "+_vm._s(_vm.parseJson(collection))+" ")])]}}],null,false,2616882239)})],1),(_vm.data.length > 0)?_c('v-tabs',{attrs:{"background-color":"blue"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"color":"green"}},[_vm._v(" Sản phẩm hợp lệ ")]),_c('v-tab',{attrs:{"color":"red"}},[_vm._v(" Sản phẩm không hợp lệ ")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.validData},scopedSlots:_vm._u([{key:"item.GiaNY",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DoiTien(item.GiaNY))+" ")]}},{key:"item.GiaNhap",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DoiTien(item.GiaNhap))+" ")]}},{key:"item.CPK",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DoiTien(item.CPK))+" ")]}}],null,true)})],1),_c('v-tab-item',_vm._l((_vm.data.filter(function (v){ return v.error; })),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(item.error)+" ")])}),0)],1)],1):_vm._e()],1):_vm._e()],1)],1),_c('v-card-text',[_c('Loader',{attrs:{"status":_vm.updateState}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"dark":!_vm.updateState,"disabled":_vm.updateState || _vm.validData.length <= 0,"loading":_vm.updateState,"color":"green"},on:{"click":_vm.doImport}},[_vm._v(" Nhập dữ liệu ")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.doClose}},[_vm._v(" Đóng ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }